import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { HardwareConsole } from '../knobs/hardwareConsole';
import { AudioManager } from './audio';
import { Clock } from './clock';
import { FaloPass } from './falopaShader';
import { UI } from './UI';
import { VideoManager } from './video';

export class Lucio {
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private renderer: THREE.WebGLRenderer;
    private composer: EffectComposer;
    private faloPass: FaloPass;
    private clock: Clock;
    private audio: AudioManager;
    private video: VideoManager;
    private quad: THREE.Mesh;

    private ui: UI;
    private hardwareConsole: HardwareConsole;

    constructor() {
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 100);
        this.camera.position.z = 1;

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x2b2b2b);

        this.ui = new UI();
        this.hardwareConsole = new HardwareConsole(this.ui);

        this.video = new VideoManager(this.hardwareConsole, this.onVideoStream);
        this.audio = new AudioManager();
        this.clock = new Clock(this.hardwareConsole);

        const quadGeometry = new THREE.PlaneGeometry(1, 1);
        const quadMaterial = new THREE.MeshBasicMaterial({
            map: this.video.getTexture(),
        });
        this.quad = new THREE.Mesh(quadGeometry, quadMaterial);
        this.scene.add(this.quad);

        this.composer = new EffectComposer(this.renderer);
        const renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(renderPass);

        this.faloPass = new FaloPass(this.hardwareConsole);
        this.composer.addPass(this.faloPass.pass);
    }

    public start = async () => {
        await Promise.all([this.hardwareConsole.start(), this.video.start(), this.audio.start()]);
        this.ui.start();
        this.clock.start();
        this.faloPass.start();

        this.resize();
        window.addEventListener('resize', this.resize);

        this.renderer.setAnimationLoop(this.render);
    };

    public dispose = () => {
        this.audio.dispose();
        this.video.dispose();
        this.hardwareConsole.dispose();
        this.clock.dispose();
        this.ui.dispose();
        this.faloPass.dispose();

        window.removeEventListener('resize', this.resize);
    };

    get domElement() {
        return this.renderer.domElement;
    }

    private onVideoStream = (aspectRatio: number) => {
        this.quad.geometry = new THREE.PlaneGeometry(aspectRatio, 1);
    };

    private resize = () => {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(window.innerWidth, window.innerHeight);
    };

    // TODO: ADD KNOB FOR OVERALL SCALE.
    private render = () => {
        const audioLevel = this.audio.getAudioLevel();

        this.clock.tick(audioLevel);

        this.faloPass.update(this.clock.elapsed, audioLevel);

        this.composer.render();
    };
}

/**
 * KNOBS:
 * 1 - 4: faloPass controls
 * 5: time scale
 * 6: _free_ BPM?
 */
