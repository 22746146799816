import { HardwareConsole } from '../knobs/hardwareConsole';
import { MathUtils } from 'three';

const BASELINE_TIME = 0.001;
const BEAT_TIME = 0.1;

export class Clock {
    private time = 0;
    private previosTime = performance.now();
    private followAudio: boolean = true;
    private useBpm: boolean = false;
    private bpmElapsedTime: number = 0;

    constructor(private hardwareConsole: HardwareConsole) {}

    public start = () => {
        this.hardwareConsole.addButtonListener('button5', this.toggleFollowAudio);
        this.hardwareConsole.addButtonListener('button4', this.toggleUseBpm);
    };

    public dispose = () => {
        this.hardwareConsole.removeButtonListener('button5', this.toggleFollowAudio);
        this.hardwareConsole.removeButtonListener('button4', this.toggleUseBpm);
    };

    public tick = (scale: number) => {
        if (this.useBpm) {
            this.tickBpm();
        } else {
            this.tickNonBpm(scale);
        }
    };

    private tickBpm = () => {
        const newTime = performance.now();
        const delta = newTime - this.previosTime;
        this.previosTime = newTime;

        // Beats per _second_
        const bps = MathUtils.mapLinear(this.hardwareConsole.readKnobs().knob5, 0, 1024, 70, 160) * 0.01666666;

        this.bpmElapsedTime += delta;
        const seconds = this.bpmElapsedTime / 1000;

        this.time += this.bumpCycle(seconds * bps + 0.5);
    };

    // Returns in [0, 1], bumps to 1 around .5 values
    // cos(x * 6 * 3.1415) * cos(x * 6 * 3.1415)
    private bumpCycle = (x: number) => {
        const normalizedX = x % 1;

        if (normalizedX < 0.41667 || normalizedX > 0.58335) {
            return BASELINE_TIME;
        } else {
            const cosX4PI = Math.cos(normalizedX * 6 * Math.PI);
            return cosX4PI * cosX4PI * BEAT_TIME;
        }
    };

    private tickNonBpm = (scale: number) => {
        const newTime = performance.now();
        const delta = newTime - this.previosTime;
        this.previosTime = newTime;

        const clockSpeed = this.hardwareConsole.readKnobs().knob6 / 1024;
        const followFactor = this.followAudio ? scale * clockSpeed : 0;

        this.time += (delta * clockSpeed + followFactor) * 0.001;
    };

    get elapsed() {
        return this.time;
    }

    private toggleFollowAudio = () => {
        this.followAudio = !this.followAudio;
    };

    private toggleUseBpm = () => {
        this.useBpm = !this.useBpm;
        if (this.useBpm) {
            this.bpmElapsedTime = 0;
        }
    };
}
