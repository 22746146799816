function hasKey<Key extends PropertyKey, T>(key: Key, value: T): asserts value is T & { [P in Key]: unknown } {
    if (!(key in value)) {
        throw new TypeError(`Value does not have a [${key}] key`);
    }
}

function isNumber(value: unknown): asserts value is number {
    if (!Number.isFinite(value)) {
        throw new TypeError(`Value [${value}] is not a number`);
    }
}

export function hasNumberKey<Key extends PropertyKey, T>(
    key: Key,
    value: T
): asserts value is T & { [P in Key]: number } {
    hasKey(key, value);
    isNumber(value[key]);
}

function isBoolean(value: unknown): asserts value is boolean {
    if (value !== true && value !== false) {
        throw new TypeError(`Value [${value}] is not a boolean`);
    }
}

export function hasBooleanKey<Key extends PropertyKey, T>(
    key: Key,
    value: T
): asserts value is T & { [P in Key]: boolean } {
    hasKey(key, value);
    isBoolean(value[key]);
}

export class ChunkCollector {
    private buffer = '';

    public addChunk = (chunk: string) => {
        this.buffer += chunk;
    };

    public getBufferedJsons = (): unknown[] => {
        try {
            // The first string is always "", remove it.
            const [, ...allPotentialJsons] = this.buffer.split('{').map((s) => '{' + s);

            const stringifiedJsons: string[] = [];

            for (const potentialJson of allPotentialJsons.slice(0, allPotentialJsons.length - 1)) {
                stringifiedJsons.push(potentialJson);
            }

            const lastPotentialJson = allPotentialJsons[allPotentialJsons.length - 1];
            if (lastPotentialJson.endsWith('}')) {
                stringifiedJsons.push(lastPotentialJson);
                this.buffer = '';
            } else {
                this.buffer = lastPotentialJson;
            }

            return stringifiedJsons.map((s) => JSON.parse(s));
        } catch (err) {
            console.error(err);
            console.warn('Retrying chunk collector');
            this.buffer = '';
            return [];
        }
    };
}
