// import { Link } from 'react-router-dom';
import './landing.css';

export const LandingComponent: React.VFC = () => {
    return (
        <div className="landing">
            <img className="landing__logo" src="Logo.png" alt="logo" />
            <div className="landing__content">
                <h1>BienvenidO</h1>
                <a href="https://youtu.be/cylvp4uCePk" rel="noreferrer" target="_blank">
                    <img src="desde.png" alt="flyer de joda" className="landing__image" />
                </a>
                {/* <Link className="button2" to="/k-hole">
                    K-HOLE
                </Link> */}
            </div>
            <div className="landing__background--right">
                <div className="landing__gradient--right" />
                <img className="landing__background-image" src="Juan.png" alt="logo" />
            </div>
            <div className="landing__background--left">
                <div className="landing__gradient--left" />
                <img className="landing__background-image" src="CestBon.png" alt="logo" />
            </div>
            {/* <img src="desde.png" alt="flyer de joda" className="landing__background" /> */}
        </div>
    );
};
