import { UI } from '../lucio/UI';
import { ChunkCollector, hasBooleanKey, hasNumberKey } from './utils';

const BAUD_RATE = 115200;

interface KnobsValues {
    knob1: number;
    knob2: number;
    knob3: number;
    knob4: number;
    knob5: number;
    knob6: number;
    knob7: number;
    knob8: number;
}

interface ButtonsValues {
    button1: boolean;
    button2: boolean;
    button3: boolean;
    button4: boolean;
    button5: boolean;
    button6: boolean;
}

type ConsoleValues = KnobsValues & ButtonsValues;

function assertIsConsoleValues(value: unknown): asserts value is ConsoleValues {
    if (typeof value !== 'object' || value === null) {
        throw new TypeError(`Value [${value}] is not a ConsoleValues`);
    }

    hasNumberKey('knob1', value);
    hasNumberKey('knob2', value);
    hasNumberKey('knob3', value);
    hasNumberKey('knob4', value);
    hasNumberKey('knob5', value);
    hasNumberKey('knob6', value);
    hasNumberKey('knob7', value);
    hasNumberKey('knob8', value);
    hasBooleanKey('button1', value);
    hasBooleanKey('button2', value);
    hasBooleanKey('button3', value);
    hasBooleanKey('button4', value);
    hasBooleanKey('button5', value);
    hasBooleanKey('button6', value);
}

type ButtonCallback = () => void;

export class HardwareConsole {
    private port: SerialPort | null = null;
    private reader: ReadableStreamDefaultReader | null = null;
    private pipeToPromise: Promise<void> = Promise.resolve();

    private values: ConsoleValues = {
        knob1: 0,
        knob2: 0,
        knob3: 0,
        knob4: 0,
        knob5: 0,
        knob6: 0,
        knob7: 0,
        knob8: 0,
        button1: false,
        button2: false,
        button3: false,
        button4: false,
        button5: false,
        button6: false,
    };

    private buttonPressCallbacks: Record<keyof ButtonsValues, Set<ButtonCallback>> = {
        button1: new Set(),
        button2: new Set(),
        button3: new Set(),
        button4: new Set(),
        button5: new Set(),
        button6: new Set(),
    };

    private collector: ChunkCollector = new ChunkCollector();

    constructor(private ui: UI) {
        // The Web Serial API is not supported.
        if (!('serial' in navigator)) {
            throw new Error('Web Serial API is not supported');
        }
    }

    public start = async (): Promise<void> => {
        this.port = await navigator.serial.requestPort();

        try {
            await this.port.open({ baudRate: BAUD_RATE });
        } catch (error) {
            console.log(error);
            return;
        }

        if (this.port.readable === null) {
            console.error('Port is not readable');
            return;
        }

        const textDecoder = new TextDecoderStream();
        this.pipeToPromise = this.port.readable.pipeTo(textDecoder.writable);
        this.reader = textDecoder.readable.getReader();

        this.startReadLoop();
    };

    public dispose = async (): Promise<void> => {
        await this.reader?.cancel();
        await this.pipeToPromise.catch(() => {
            /* ignore error, for some reason. Stupid API */
        });
        await this.port?.close();
    };

    /**
     * Returns the latest locally stored values
     */
    public readKnobs = (): ConsoleValues => {
        return this.values;
    };

    public addButtonListener = (button: keyof ButtonsValues, callback: () => void) => {
        this.buttonPressCallbacks[button].add(callback);
    };

    public removeButtonListener = (button: keyof ButtonsValues, callback: () => void) => {
        this.buttonPressCallbacks[button].delete(callback);
    };

    private pressButton = (button: keyof ButtonsValues) => {
        this.buttonPressCallbacks[button].forEach((cb) => {
            cb();
        });
        this.ui.postButtonClick(button);
    };

    private startReadLoop = async (): Promise<void> => {
        if (this.reader === null) {
            throw new Error("You can't start the read loop without starting the knobsReader, you knob head");
        }

        try {
            while (true) {
                const result = await this.reader.read();

                if (result.done) {
                    console.log('Stream closed');
                    break;
                }

                this.collector.addChunk(result.value);

                for (const jsons of this.collector.getBufferedJsons()) {
                    assertIsConsoleValues(jsons);
                    this.updateValues(jsons);
                }
            }
        } catch (error) {
            console.error(error);
            return;
        }
    };

    private updateValues = (newValues: ConsoleValues) => {
        if (this.values.button1 === false && newValues.button1 === true) {
            this.pressButton('button1');
        }
        if (this.values.button2 === false && newValues.button2 === true) {
            this.pressButton('button2');
        }
        if (this.values.button3 === false && newValues.button3 === true) {
            this.pressButton('button3');
        }
        if (this.values.button4 === false && newValues.button4 === true) {
            this.pressButton('button4');
        }
        if (this.values.button5 === false && newValues.button5 === true) {
            this.pressButton('button5');
        }
        if (this.values.button6 === false && newValues.button6 === true) {
            this.pressButton('button6');
        }

        // Hardware console gives values in [1024 - 0]. Invert them.
        newValues.knob1 = 1024 - newValues.knob1;
        newValues.knob2 = 1024 - newValues.knob2;
        newValues.knob3 = 1024 - newValues.knob3;
        newValues.knob4 = 1024 - newValues.knob4;
        newValues.knob5 = 1024 - newValues.knob5;
        newValues.knob6 = 1024 - newValues.knob6;
        newValues.knob7 = 1024 - newValues.knob7;
        newValues.knob8 = 1024 - newValues.knob8;

        this.values = newValues;
        this.ui.postKnobs(this.values);
    };
}
