import * as THREE from 'three';
import { HardwareConsole } from '../knobs/hardwareConsole';

type OnVideoStreamCallback = (aspectRatio: number) => void;

export class VideoManager {
    private videoElement: HTMLVideoElement;
    private videoSources: MediaDeviceInfo[];
    private currentVideoSourceIndex: number;
    private isReconnecting: boolean;
    private stream: MediaStream | null;

    constructor(private hardwareConsole: HardwareConsole, private onVideoStream: OnVideoStreamCallback) {
        this.videoElement = document.createElement('video');
        this.videoSources = [];
        this.currentVideoSourceIndex = 0;
        this.isReconnecting = false;
        this.stream = null;
    }

    /**
     * Returns the aspect ratio of the webcam's video
     */
    public start = async () => {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        this.videoSources = allDevices.filter((d) => d.kind === 'videoinput');

        this.cycleVideoSources();
        this.hardwareConsole.addButtonListener('button6', this.cycleVideoSources);
    };

    public dispose = () => {
        this.closeCurrentStream();
        this.hardwareConsole.removeButtonListener('button6', this.cycleVideoSources);
    };

    public getTexture = () => {
        return new THREE.VideoTexture(this.videoElement);
    };

    private setVideoSource = async (deviceId: string) => {
        if (this.isReconnecting) {
            return;
        }
        this.isReconnecting = true;

        this.closeCurrentStream();

        const constraints = {
            video: {
                height: {
                    ideal: 720,
                },
                facingMode: 'user',
                deviceId,
            },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        this.videoElement.srcObject = stream;
        await this.videoElement.play();
        this.onVideoStream(this.videoElement.videoWidth / this.videoElement.videoHeight);

        this.isReconnecting = false;
    };

    private closeCurrentStream = () => {
        if (this.stream === null) {
            return;
        }

        for (const track of this.stream.getTracks()) {
            if (track.readyState === 'live') {
                track.stop();
                this.stream.removeTrack(track);
            }
        }

        this.stream = null;
    };

    private cycleVideoSources = () => {
        if (this.videoSources.length === 0) {
            return;
        }

        this.currentVideoSourceIndex = (this.currentVideoSourceIndex + 1) % this.videoSources.length;
        const id = this.videoSources[this.currentVideoSourceIndex].deviceId;

        void this.setVideoSource(id);
    };
}
