import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useLazyRef } from './hooks/useLazyRef';
import { LandingComponent } from './landing/landing';
import { Lucio } from './lucio/lucio';

import './App.css';

type CallbackRef = (element: HTMLElement | null) => void;

export const useLucio = (): CallbackRef => {
    const lucio = useLazyRef(() => new Lucio());

    useEffect(() => {
        void lucio.start();

        const onClose = () => lucio.dispose();

        window.addEventListener('unload', onClose);

        return () => {
            onClose();
            window.removeEventListener('unload', onClose);
        };
    }, [lucio]);

    const callbackRef = (element: HTMLElement | null): void => {
        if (element !== null) {
            element.appendChild(lucio.domElement);
        }
    };

    return callbackRef;
};

const LucioComponent: React.VFC = () => {
    const ref = useLucio();

    return <div className="lucio-container" ref={ref} />;
};

export const App: React.VFC = () => {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route path="/k-hole">
                        <LucioComponent />
                    </Route>
                    <Route path="/">
                        <LandingComponent />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
};
