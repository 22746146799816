export class UI {
    private UIwindow: Window | null = null;

    public start = () => {
        this.UIwindow = window.open('ui.html', '_blank', 'resizable=no,scrollbars=no,status=no,width=1000,height=600');
    };

    public dispose = () => {
        this.UIwindow?.close();
    };

    public postKnobs = (knobs: any) => {
        this.UIwindow?.postMessage(JSON.stringify(knobs));
    };

    public postButtonClick = (button: string) => {
        this.UIwindow?.postMessage(JSON.stringify({ click: button }));
    };
}
