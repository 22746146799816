export class AudioManager {
    private audioCtx: AudioContext;
    private analyser: AnalyserNode;
    private dataArray: Uint8Array;
    private audioSources: MediaDeviceInfo[];
    private currentAudioSourceIndex: number;
    private currentSource: MediaStreamAudioSourceNode | null;
    private isReconnecting: boolean;
    private stream: MediaStream | null;

    constructor() {
        this.audioCtx = new AudioContext();

        this.analyser = this.audioCtx.createAnalyser();
        this.analyser.fftSize = 2048;
        const bufferLength = this.analyser.frequencyBinCount;
        this.dataArray = new Uint8Array(bufferLength);
        this.audioSources = [];
        this.currentAudioSourceIndex = 0;
        this.currentSource = null;
        this.isReconnecting = false;
        this.stream = null;
    }

    public start = async () => {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        this.audioSources = allDevices.filter((d) => d.kind === 'audioinput');

        this.cycleAudioSources();
        document.addEventListener('keypress', this.onCtrlSpace);
    };

    public dispose = () => {
        this.closeCurrentStream();
        document.removeEventListener('keypress', this.onCtrlSpace);
    };

    public getAudioLevel = () => {
        this.analyser.getByteFrequencyData(this.dataArray);

        const sum = this.dataArray.reduce((acc, curr) => acc + curr);
        return sum / this.dataArray.length / 256;
    };

    private setAudioSource = async (deviceId: string) => {
        if (this.isReconnecting) {
            return;
        }
        this.isReconnecting = true;

        this.closeCurrentStream();

        this.currentSource?.disconnect(this.analyser);

        this.stream = await navigator.mediaDevices.getUserMedia({
            audio: { deviceId },
        });

        this.currentSource = this.audioCtx.createMediaStreamSource(this.stream);
        this.currentSource.connect(this.analyser);

        this.isReconnecting = false;
    };

    private cycleAudioSources = () => {
        if (this.audioSources.length === 0) {
            return;
        }

        this.currentAudioSourceIndex = (this.currentAudioSourceIndex + 1) % this.audioSources.length;
        const id = this.audioSources[this.currentAudioSourceIndex].deviceId;

        void this.setAudioSource(id);
    };

    private closeCurrentStream = () => {
        if (this.stream === null) {
            return;
        }

        for (const track of this.stream.getTracks()) {
            if (track.readyState === 'live') {
                track.stop();
                this.stream.removeTrack(track);
            }
        }

        this.stream = null;
    };

    private onCtrlSpace = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.code === 'Enter') {
            this.cycleAudioSources();
        }
    };
}
